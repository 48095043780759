import { useEffect, useState } from 'react'
import { Stack, Flex, Text, Box, Button, Link as ReachLink} from '@chakra-ui/react'
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import LoadingScreen from './LoadingScreen';
import BackButton from './utils/BackButton';

const UserInfo = ({currentUser, urls}) => {
    const [username, setUsername] = useState('')
    const [tokens, setTokens] = useState(0)
    const [paymentEnabled, setPaymentEnabled] = useState(true)
    const [loading, setLoading] = useState(true)
    const [email, setEmail] = useState('')
    const [complementaryUser, setComplementaryUser] = useState(false)

  useEffect(() => {
    setLoading(true)
    
    const fetchUserInfo = async () => {
      let tokenToSend = await currentUser.getIdToken()
      
      const incoming = {
        user_id: currentUser.uid,
        token: tokenToSend,
        user_object: currentUser
        };
    
        try {
            const response = await fetch(`${urls.text_api}/fetchuser`, {
            method: 'POST',
            body: JSON.stringify(incoming),
            headers: {
                'Content-Type': 'application/json'
            }
            })
            const data = await response.json()
            console.log(data)
            setUsername(data.user.username)
            setTokens(data.user.free_credits)
            if (data.user.free_credits !== 0){
              setComplementaryUser(true)
            }
            setPaymentEnabled(data.user.payment_setup)
            setEmail(data.user.email)
            console.log(data.user.payment_setup)
        } catch (error) {
            console.error(error)
        }
        setLoading(false)
    }


    fetchUserInfo()


    },[])




  return (
    <>
    {loading ? (<LoadingScreen></LoadingScreen>) : (
    <Box minHeight="70vh"  display="flex" justifyContent="center" alignItems="center">
    <Stack align="center" spacing={4}>
      <Flex>
        <Text fontSize="lg">Username:</Text>
        <Text fontSize="lg" fontWeight="bold" ml={2}>
          {username}
        </Text>
      </Flex>
      <Flex>
        <Text fontSize="lg">Email:</Text>
        <Text fontSize="lg" fontWeight="bold" ml={2}>
          {email}
        </Text>
      </Flex>
      <>
      {
      complementaryUser ? (
        <Flex>
        <Text fontSize="lg">Complementary Tokens: </Text>
        <Text fontSize="lg" fontWeight="bold" ml={2}>
          {tokens}
        </Text>
      </Flex>
      ) : (null)
      }
      </>
      <Flex>
        <Text fontSize="lg">Billing enabled:</Text>
        <Text fontSize="lg" fontWeight="bold" ml={2}>
          {paymentEnabled ? (
            <CheckIcon color="green.500" />
          ) : (

            <CloseIcon  color="red.500"/>
 
          )}
        </Text>
      </Flex>
      {paymentEnabled ? (<Link to="/billingsetup">
            <Button>Change billing</Button>
            </Link>):(            
            <Link to="/billingsetup">
            <Button>Billing Quick Setup</Button>
            </Link>)}
    
            <Link to="/">
            <BackButton></BackButton>
            </Link>
    </Stack>
    </Box>
    )}
    </>
  );
};

export default UserInfo;