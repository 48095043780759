import { useEffect, useState } from 'react';
import { AnimatePresence, Spinner, Select, Icon, Grid, FormLabel, Input, Flex, Box, Text, Drawer, useDisclosure, DrawerOverlay, DrawerContent, DrawerHeader, DrawerBody, Button, DrawerCloseButton, Spacer, Fade } from '@chakra-ui/react';
import SimpleForm from '../forms/SimpleForm';
import EssayHolder from '../forms/EssayHolder';
import {
    SettingsIcon
  } from '@chakra-ui/icons';

const EmailGenerator = ({currentUser , setBillRemind, urls}) => {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [waitingResponse, setWaitingResponse] = useState(false)

    const [currentEmail, setCurrentEmail] = useState("")

    const [goals, setGoals] = useState("")
    const [notes, setNotes] = useState("")
    const [currentType, setCurrentType] = useState("Business")
    const [specialMessage, setSpecialMessage] = useState("")
    const [intoFirst, setIntroFirst] = useState(true)
    const [specialMessageFlag, setSpecialMessageFlag] = useState(0)
    const specialMessages = {"intro_done": "<- Happy with your section? Change to the next section."}

    //
    const [primaryTopic, setPrimaryTopic] = useState('')
    const [secondaryTopics, setSecondaryTopics] = useState('')
    const [audience, setAudience] = useState('')
    const [complexity, setComplexity] = useState("Medium")
    const [numParagraphs, setNumParagraphs] = useState(1)

    // useEffect(()=>{console.log("goals", goals, notes)},[goals,notes])
 // TODO do try except
    useEffect(() => {
        const savedState = localStorage.getItem(`${currentUser.uid}emailGenState`);
        if (savedState) {
          const state = JSON.parse(savedState);
          state.primaryTopic && setPrimaryTopic(state.primaryTopic);
          state.secondaryTopics && setSecondaryTopics(state.secondaryTopics);
          state.audience && setAudience(state.audience);
          state.goals && setGoals(state.goals);
          state.notes && setNotes(state.notes);
          state.currentEmail && setCurrentEmail(state.currentEmail);
        }

      }, []);
    



    useEffect(()=>{
        const saveState = () => {
            const state = {
                primaryTopic,
                secondaryTopics,
                audience,
                goals,
                notes,
                currentEmail,
            };
            localStorage.setItem(`${currentUser.uid}emailGenState`, JSON.stringify(state));
            }
            saveState()

    },[primaryTopic,
        secondaryTopics,
        audience,
        goals,
        notes,
        currentEmail,
        currentUser
        ])

 
// utils ///////

const resetTopicWriter = () => {
    setWaitingResponse(false);
    setCurrentEmail("");
    setGoals("");
    setNotes("");
    currentType("Business");
    setSpecialMessage("");
    setPrimaryTopic("");
    setSecondaryTopics("")
  };



const submitEmail = async () =>{

    let tokenToSend = await currentUser.getIdToken()
    let dataToSend = { 
                    // generation info
                    generationInfo :{
                        // note this logic needs to exclude 
                        // the middle and conclusion
                        emailType: currentType,
                        goals: goals,
                        notes: notes,
                        primaryTopic: primaryTopic,
                        secondaryTopics: secondaryTopics,
                        audience: audience,
                        complexity: complexity,
                    },
                    // user info
                    user_id: currentUser.uid,
                    token: tokenToSend,
                    user_object: currentUser
                }
try {
    const response = await fetch(`${urls.text_api}/generate/email`, {
    method: 'POST',
    body: JSON.stringify(dataToSend),
    headers: {
        'Content-Type': 'application/json'
    }
    })
    const data = await response.json()
    console.log(data)
    setCurrentEmail(data.result)
    } catch (error) {
        console.error(error)
    }
}
/////////////////////////////////////////////////////////////////

    const handleSubmission = async () => {
        setWaitingResponse(true)
        console.log("submitting work")

        await submitEmail()

        setWaitingResponse(false)
    }

    const handleSectionSelectChange = () =>{
        if (specialMessageFlag <= 3){
            setSpecialMessage("")
        }
        console.log("here")
    }

    return (

        <>  
            <Box maxW={["80%","75%","70%"]} mx="auto" mt={8}>
            <Flex  justifyContent={"space-between"}>
            <Flex flexDirection={["column", "column", "row"]} marginBottom={"40px"}>
                <Text paddingLeft={["0px"]} fontSize={"sm"} marginBottom={["10px"]}>Email Type: </Text>
                <Select marginBottom={"10px"} marginLeft={"20px"} width={["120px","150px","160px"]} value={currentType} onChange={
                    (event) => {
                setCurrentType(event.target.value) 
                handleSectionSelectChange()}}>
                    <option value="Business">Business</option>
                    <option value="Casual">Casual</option>
                </Select>
                {/* <Text marginLeft={"15px"} fontWeight={"bold"} fontSize={"20px"} marginBottom={["20px"]}> {currentSection}</Text> */}
                {/* <Text color={"green.500"} paddingTop={"5px"} marginLeft={"15px"} fontWeight={"bold"} >{specialMessage}</Text> */}
            <>

                <>
                {/* <Text marginLeft={["0px","0px","10px"]} marginBottom={["10px"]} fontSize={["sm"]}>Detail Level:</Text>
            <Select  marginLeft={["20px"]}width={["120px","150px","160px"]} value={complexity} onChange={
                    (event) => {
                setComplexity(event.target.value) }}>
                    <option value={"Low"}>Low</option>
                    <option value={"Medium"}>Medium</option>
                    <option value={"High"}>High</option>
                </Select> */}
            </>

            </>
            </Flex>
            {/* <Flex>
            <Text>{complexityStatement}</Text>
            <Select  marginLeft={["10px"]}width={["80px"]} value={complexity} onChange={
                    (event) => {
                setComplexity(event.target.value) }}>
                    <option value={1}>1</option>
                    <option value={1}>2</option>
                    <option value={1}>3</option>
                </Select>
            </Flex> */}
            <Flex dir="row">    
            <Button colorScheme={"red"} variant={"outline"} onClick={resetTopicWriter}>Reset</Button>
            </Flex> 
            </Flex>

            <Grid templateColumns={['1fr', '1fr', '1fr 1fr']} gap={4}>
            {/* <Grid templateColumns={['1fr', '1fr', '1fr 1fr 1fr']} gap={4}>*/}
            <Flex dir="row">
            <FormLabel htmlFor="primaryTopic" fontSize="sm" display={primaryTopic ? 'block' : 'none'}>
        Primary topic
      </FormLabel>
                <Input type="text" placeholder="Primary topic" value={primaryTopic} onChange={event => setPrimaryTopic(event.target.value)}  />
                </Flex>
                <Flex dir="row">
                <FormLabel htmlFor="secondaryTopics" fontSize="sm" display={secondaryTopics ? 'block' : 'none'}>
        Secondary topics
      </FormLabel>
                <Input type="text" placeholder="Secondary topics" value={secondaryTopics} onChange={event => setSecondaryTopics(event.target.value)}  />
                </Flex>
                {/* <Flex dir="row">    
        <FormLabel htmlFor="audience" fontSize="sm" display={audience ? 'block' : 'none'}>
        Audience
      </FormLabel>
                <Input type="text" placeholder="Audience" value={audience} onChange={event => setAudience(event.target.value)} />
                </Flex>    */}

            </Grid>
                {/* goals input */}
                <Box margin={["20px 0px"]}>
                    <Text>Goals</Text>
                    <SimpleForm
                        setValue={setGoals} value={goals}
                    />
                </Box>

                {/* goals input */}
                <Box  margin={["20px 0px"]}>
                    <Text>Notes & References</Text>
                    <SimpleForm
                        setValue={setNotes} value={notes}
                    />
                </Box>
                <Button onClick={handleSubmission} margin={["30px"]} marginTop={["15px"]}>Write {currentType} Email</Button>
                <>
                {waitingResponse ? (
                    <Spinner
                thickness='4px'
                speed='0.95s'
                emptyColor='gray.200'
                size='md'
                />
                ):(null)}

                </>
                <EssayHolder marginBottom={["200px"]} margin={["20px 0px"]} label={"Work so far"} valueOfText={currentEmail}></EssayHolder>
                <Box height={"200px"}></Box>
            </Box>
            {/* <Button position={"fixed"} left="5px" top="50px" colorScheme='blue' onClick={onOpen}>
            Settings
            </Button> */}
            <Button width={["4px","auto","auto"]}
            position={"fixed"} left="5px" top="50px"
            size="sm"
            colorScheme='blue'
            aria-label="Settings"
            fontSize="lg"
            onClick={onOpen}
      ><SettingsIcon display={["block","block","none"]} visibility={["visible","visible","hidden"]}></SettingsIcon> 
      <Text display={["none","none","block"]} visibility={["hidden","hidden","visible"]} >Settings</Text></Button>
      
            {/* TODO seperate this into component */}
            <Drawer placement={"left"} onClose={onClose} isOpen={isOpen}>
                <DrawerOverlay/>
                <DrawerContent>
                <DrawerCloseButton></DrawerCloseButton>
                    <DrawerHeader borderBottomWidth='1px'>Advanced Settings</DrawerHeader>
                    <DrawerBody>
                        <p>More settings coming soon..</p>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>


    );
};

export default EmailGenerator;
