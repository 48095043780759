import { useState } from 'react';
import { Text, Textarea } from '@chakra-ui/react';

const SimpleForm = ({ label, setValue, value }) => {


  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const clearForm = () => {
    setValue('');
  };

  return (
    <>
        <Text htmlFor="textarea">{label}</Text>
        <Textarea border={"1px"} id="textarea" value={value} onChange={handleChange} />
    </>

  );
};

export default SimpleForm;
