import { Grid, Box, Text} from "@chakra-ui/react";
import { Link } from "react-router-dom";


function ToolSelect() {
  return (
    <Grid padding={"5%"} templateColumns={["repeat(1, 1fr)", "repeat(2, 1fr)","repeat(3, 1fr)"]} gap={6}>
        <Link to="/tools/emailwizard">
      <Box _hover={{boxShadow : "dark-lg"}} boxShadow={"2xl"} borderRadius={"20px"} height={"35vh"} backgroundColor="yellow.400" p={5} fontSize="xl">
      <Text fontSize={"xl"}>Email Wizard</Text> 
      <br></br>
        <br></br>
      <Text _hover={{boxShadow : "dark-lg red"}} boxShadow={"2xl"}  fontSize={"md"} textDecor={"underline"}>New! - Click to Start</Text>
      </Box>
      </Link>
      <Link to="/tools/topicwriter">
      <Box _hover={{boxShadow : "dark-lg"}} boxShadow={"2xl"}  borderRadius={"20px"} backgroundColor="blue.400" height={"35vh"}  p={5} fontSize="xl">
        <Text fontSize={"xl"}>Topic Writer</Text>
        <br></br>
        <br></br>
        <Text fontSize={"md"} textDecor={"underline"}>Our First Alpha Product - Try it Now!</Text>
      </Box>
      </Link>
      <Box _hover={{boxShadow : "dark-lg"}} boxShadow={"2xl"}  borderRadius={"20px"} height={"35vh"} backgroundColor="teal.400" p={5}>
      <Text fontSize={"xl"} >Super Editor</Text>
      <Text fontSize={"md"}>coming soon</Text> 
      </Box>
      <Box _hover={{boxShadow : "dark-lg"}} boxShadow={"2xl"}  borderRadius={"20px"} height={"35vh"}  backgroundColor="purple.400" p={5} fontSize="xl">
      <Text fontSize={"xl"}>Quote of the day</Text>
      <Text fontSize={"md"}>coming soon</Text> 
      </Box>
    </Grid>
  );
}

export default ToolSelect;