import {Button, Center, Flex, Text,  link as ReachLink, Image } from "@chakra-ui/react";
// import React, { useState } from "react";
import { Link } from "react-router-dom"
import logo from "../assets/logo1.png"
const InnerLanding = ({currentUser}) => {

  return (
    <Center h={"70vh"} _dark={{background: "#1a202c"}}>
    <Flex direction={"column"} textAlign={"center"}>
    <Text fontSize={["50px","50px","50px"]} textAlign={"center"} fontWeight="bold" >Welcome To Textmatica</Text>
    <Center marginBottom={["-20px"]} ><Image width={"75px"} src={logo}></Image></Center>
<Text margin={"25px"} marginBottom={"35px"} >Inspire your work, and improve your writing.</Text>
<Link to={"/tools"} ><Button>Let's GO!</Button></Link>
</Flex>
</Center>
  );
};

export default InnerLanding;