import { Box, Button, Center, Flex, Image, Link, Text } from "@chakra-ui/react";
// import React, { useState } from "react";
import glogo from "../assets/googlelogo.png"
import logo from "../assets/logo1.png"

const FirstLanding = ({clickGoogleSignin}) => {

  return (
	<Flex margin={0} direction="column" h="70vh" justify="center" align="center">
    <Flex align="center">
    <Box fontSize={["50px","50px","50px"]} textAlign={"center"} fontWeight="bold" margin={"10px"}>Textmatica</Box>
    <Center marginBottom={["-8px"]} ><Image width={"75px"} src={logo}></Image></Center>
    </Flex>
    <Flex align="center">
    <Box fontSize={["15px","15px","15px"]} textAlign={"center"} maxW={"50vw"}>Writing, but fun.</Box>
    </Flex>
    <Flex align="center">
    <Box fontSize={["15px","20px","20px"]} margin={"20px"} textAlign={"center"} maxW={"50vw"}>Sign in with Google to access our Alpha</Box>
    </Flex>
    <Flex align="center">
    <Button onClick={clickGoogleSignin} backgroundColor={"transparent"} border={"2px"} width={["40vw","30vw","20vw"]} margin={"20px"}><Image  paddingRight={["10px"]} maxH={"80%"} src={glogo}></Image>Sign in</Button>
    </Flex>
	</Flex>
    
  );
};

export default FirstLanding;