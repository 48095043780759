import {React,
  useState, 
  useEffect
} from 'react';
import theme from './theme';
import {
  Center,
  ChakraProvider,
  link as ReachLink,
  extendTheme,
  useDisclosure,
} from '@chakra-ui/react';
import FadeInOut from './components/utils/FadeInOut';
import { mode } from '@chakra-ui/theme-tools';

import Navbar from './components/Navbar'
import { v4 as uuidv4 } from 'uuid';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom"
import config from "./config.json"

import { getAnalytics } from "firebase/analytics";
import {Elements} from '@stripe/react-stripe-js';
import { GoogleAuthProvider,
  getAuth,
signInWithPopup,
signInWithEmailAndPassword,
createUserWithEmailAndPassword,
sendPasswordResetEmail,
signOut,
onAuthStateChanged
}
from "firebase/auth";
import {getFirestore,
  query,
  getDocs,
  collection,
  setDoc,
  doc,
  where,
  addDoc,
  getDoc,
}from "firebase/firestore";
import FirstLanding from './components/FirstLanding';
import InnerLanding from './components/innerLanding';
import LoadingScreen from './components/LoadingScreen';
import TopicWriter from './components/TopicWriter';
import UserInfo from './components/UserInfo';
import BillRemindModal from './components/modals/BillRemindModal';

// import { useAuthState } from "react-firebase-hooks/auth";
// import { createCheckoutSession } from "./stripe/createCheckoutSession";
// import usePremiumStatus from "./stripe/usePremiumStatus";
import app from "./firebase/firebaseClient"
import PaymentSetup from './components/payments/PaymentSetup';
import PaymentStatus from './components/payments/PaymentStatus';
import {loadStripe} from '@stripe/stripe-js';
import ToolSelect from './components/ToolSelect';
import EmailGenerator from './components/tools/EmailGen';


// Initialize Firebase
// const app = initializeApp(firebaseConfig); // now brought in from file
const analytics = getAnalytics(app);
const db = getFirestore(app); 
/////////////////////// end firebase imports
let devmode = config.devmode;
let set_id_mode = config.set_id_mode;

let backend_url;
let item_url;
let cur_user_id;
let payments_api
let text_api;
let urls ={};
// const backend_url = "https://gifty-web-app-1-md6m2cnd6q-ue.a.run.app/smsdirect_data"

// local
if (devmode){
backend_url = ""
payments_api = config.payments_api_dev
text_api = config.text_api_dev
cur_user_id = "123"
}
else{
console.log("NOT IN DEV MODE")
backend_url = ""
payments_api = config.payments_api
text_api = config.text_api

if (set_id_mode){
  cur_user_id = "123"
}
else{
  cur_user_id = uuidv4()
}
}

urls.payments_api = payments_api
urls.text_api = text_api

// const stripePromise = loadStripe('pk_test_51MK5QhCqoiMrzvUQrPPukqtUTrpya4AK5ySUsjyqDU4D6MgRFp9lfMEmxYCQl5pHOfDLbQ3jBrgXNG3XQaIrLSXa00yyDC6LAJ');
const stripePromise = loadStripe('pk_live_51MK5QhCqoiMrzvUQRoRFGrtmHt1zOgN8Pqvu6UE0hOM9DFpXcweLXtC2OdZImIzqypKvfloglFRwATisUTvAjrJm00Cii0IyW9');
const provider = new GoogleAuthProvider();


function App() {
  const {isOpen, onOpen, onClose } = useDisclosure()
  const [password, setPassword] = useState("");
  // const [recItem, setRecItem] = useState("Holiday+Gifts");
  const [productsOpen, setProductsOpen] = useState(false);
  const [fetchingStatus, setFetchingStatus] = useState(false);
  const [productButtonName, setProductButtonName] = useState("See Products");
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState("")
  // const userIsPremium = usePremiumStatus(currentUser);
  // const [firstRender, setFirstRender] = useState(true)
  const [loading, setLoading] = useState(true)

  const [billRemind, setBillRemind] = useState(false)
  const [billRemindDismissed, setBillRemindDismissed] = useState(false)


  const remindAboutBilling = () =>{
    setBillRemind(true)
    setBillRemindDismissed(false)
  }

  // useEffect(()=>{remindAboutBilling()},[])

  // useEffect(()=>{
  //   console.log("remind about billing?", billRemind)

  // },[billRemind])

  async function createUser(incoming) {
    try {
      const response = await fetch(`${urls.text_api}/createuserpublic`, {
        method: 'POST',
        body: JSON.stringify(incoming),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  }

  async function createStripeUser(incoming) {
    try {
      const response = await fetch(`${urls.payments_api}/createstripecustomer`, {
        method: 'POST',
        body: JSON.stringify(incoming),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  }


  useEffect(() => {
    let stored_password = window.localStorage.getItem('password')

    setPassword(stored_password)
      
      const auth = getAuth();

      onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        console.log(user)
        try {
          const incoming = {
            user_id: uid,
            token: user.accessToken,
            user_object: user
          };

          createUser(incoming)
          createStripeUser(incoming)

        } catch (e) {
          console.error("Error Creating User: ", e);
        }
        // console.log(user, "yo")

        setCurrentUser(user)
        setIsSignedIn(true)
        // ...
      } else {
        // User is signed out
        // ...
        setIsSignedIn(false)
        setCurrentUser("")
        console.log("User is signed out")
      }
    });

    setTimeout(() => setLoading(false), 2000)

}, []);



const fetchItemResponse = async () =>{
  let total_url = item_url + "?user_id=" + cur_user_id
	let result = await fetch(total_url)
	let result_to_json = await result.json()
	console.log(result_to_json)
	return result_to_json
}

const handleGetItem = async () => {

  // setFetchingStatus(true)
	let response = await fetchItemResponse()
  // setRecItem(response.message)
  window.current_search = response.message
  console.log(response)
	// document.getElementById('testframe').contentWindow.location.reload();

	// setFetchingStatus(false)
  };

  function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}


  const handleLogout = () => {
    const auth = getAuth();
    auth.signOut().then(function() {
      console.log('Signed Out');
    }, function(error) {
      console.error('Sign Out Error', error);
    });
  }

  const clickGoogleSignin = () =>{
    // setIsSignedIn(true)
    

const auth = getAuth();
signInWithPopup(auth, provider)
  .then((result) => {
    // This gives you a Google Access Token. You can use it to access the Google API.
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;
    // The signed-in user info.
    const user = result.user;
    // db.collection("users").doc(credential.user.uid).set({
    //   uid: credential.user.uid,
    //   email: credential.user.email,
    //   name: credential.user.displayName,
    //   provider: credential.user.providerData[0].providerId,
    //   photoUrl: credential.user.photoURL,
    // });
    // ...
    console.log(user,"signing in with popup")
  }).catch((error) => {
    // Handle Errors here.
    console.log(error)
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.customData.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);
    // ...
  });

  }
  // const config = {
  //   initialColorMode: 'dark',
  //   useSystemColorMode: false,
  //   body: {
  //     color: mode('gray.800', 'whiteAlpha.900')(props),
  //     bg: mode('gray.100', '#141214')(props),
  //   }
  // }
  // const theme = extendTheme({ config })

  return (
    <ChakraProvider theme={theme}>
    <Router>
    {/* {billRemind && !billRemindDismissed ? (<BillRemindModal billRemindDismissed={billRemindDismissed}></BillRemindModal>):(null)} */}
    {/* <BillRemindModal billRemind={billRemind} billRemindDismissed={billRemindDismissed}></BillRemindModal> */}

      {loading ? (<LoadingScreen></LoadingScreen>) : (
        <>
        <BillRemindModal setBillRemind={setBillRemind} billRemind={billRemind} billRemindDismissed={billRemindDismissed}></BillRemindModal>
        {!isSignedIn ? (null) :(<Navbar handleLogout={handleLogout} modalOnOpen = {onOpen} currentUser={currentUser}></Navbar>
        )}
          {!isSignedIn && !loading ?(    <FadeInOut show={true} duration={500}>
          <FirstLanding clickGoogleSignin={clickGoogleSignin}></FirstLanding>
          </FadeInOut>): (
          <Switch>
          <Route exact path={["/","/landing"]}>
          {/* <Link to="/test" >test link</Link> */}
          {/* {!isSignedIn && !loading ?(<FirstLanding clickGoogleSignin={clickGoogleSignin}></FirstLanding>): (null)} */}
          {isSignedIn && !loading ?(
          <FadeInOut show={true} duration={500}>
          <InnerLanding currentUser={currentUser} clickGoogleSignin={clickGoogleSignin}>
          </InnerLanding>
          </FadeInOut>
          ):(null)}
          </Route>
            
          <Route path={"/userinfo"} >
              <UserInfo urls={urls} currentUser={currentUser} />
            </Route>

          <Route path={"/billingsetup"}>

            <PaymentSetup urls={urls} currentUser={currentUser}  clickGoogleSignin={clickGoogleSignin}></PaymentSetup>

          </Route>

          <Route exact path="/tools">

            <ToolSelect urls={urls} currentUser={currentUser} setBillRemind={setBillRemind} /> 

         </Route>

          <Route path={"/paymentstatus"}>
            <Elements stripe={stripePromise}>
            <PaymentStatus urls={urls} currentUser={currentUser} clickGoogleSignin={clickGoogleSignin}></PaymentStatus>
            </Elements>
          </Route>
          <Route exact path="/tools/topicwriter">
            <TopicWriter urls={urls} currentUser={currentUser} setBillRemind={setBillRemind} /> 
          </Route>
          

          <Route exact path="/tools/emailwizard">
            <EmailGenerator urls={urls} currentUser={currentUser} setBillRemind={setBillRemind} /> 
          </Route>

          <Redirect from="*" to="/" />
          </Switch>
      )}
          </>
         
      )}
      </Router>
    </ChakraProvider>
  );
}

export default App;
