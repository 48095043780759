import { Center } from '@chakra-ui/react';
import React from 'react';
import DiamondDots from "./utils/DiamondDots"


export default function LoadingScreen() {

  return (
    <div>
    <Center h={"100vh"} _dark={{background: "#1a202c"}} background="white">
    <DiamondDots></DiamondDots>
    </Center>
    </div>
  );


}
