import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
    Text
  } from '@chakra-ui/react'
import { useEffect } from 'react';
import { Link } from 'react-router-dom';


  function BillRemindModal({billRemind, setBillRemind, billRemindDismissed}) {
    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() =>{
        if (billRemind === true){
            onOpen()
        }
    }
    ,[billRemind])

    const handleOnClose = () =>{
        onClose()
        setBillRemind(false)
    }

    return (
      <>
        <Modal isCentered isOpen={isOpen} onClose={handleOnClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Please Enable Billing</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>Thanks for using Textmatica! We're glad you've been able to use our service at no charge thanks to your complementary tokens. To keep the service running and available to you, we need your help with billing. Enabling billing helps us continue providing you with this valuable tool. Thanks for your support – we appreciate it!</Text>
            </ModalBody>
            <ModalFooter>
            <Link to ="/billingsetup">
              <Button onClick={handleOnClose} colorScheme='green' mr={3}>
                Quick Setup
              </Button>
              </Link>
              {/* <Button variant='ghost'>Secondary Action</Button> */}
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }

  export default BillRemindModal;