import { Button, Center, Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import SetupForm from "./SetupForm";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import LoadingScreen from "../LoadingScreen";
const stripePromise = loadStripe('pk_live_51MK5QhCqoiMrzvUQRoRFGrtmHt1zOgN8Pqvu6UE0hOM9DFpXcweLXtC2OdZImIzqypKvfloglFRwATisUTvAjrJm00Cii0IyW9');

const PaymentSetup = ({currentUser, urls}) => {
  const [setupSecret, setSetupSecret] = useState(null)

  const appearance = {
    theme: 'stripe'
  };

  useEffect(()=>{

    // const getSecret = async () => {
    //   const response = await fetch(`${urls.payments_api}/secret`);
    //   let recievedSetupSecret = await response.json();
    //   console.log(recievedSetupSecret)
    //   setSetupSecret(recievedSetupSecret.client_secret)
    //   // Render the form using the clientSecret
    // }

    const getSecret = async (user) => {
      const response = await fetch(`${urls.payments_api}/createsetupintent`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ user_object: user })
      });
      let receivedSetupSecret = await response.json();
      console.log(receivedSetupSecret)
      setSetupSecret(receivedSetupSecret.client_secret)
      // Render the form using the clientSecret
    }

    console.log("the stripe page")
    getSecret(currentUser)

  },[currentUser,urls])

  
  return (
    <>
    <Center>
    <>
    { (true) ? ( 
    (setupSecret != null) ? ( 
    <Elements appearance={appearance} stripe={stripePromise} options={{clientSecret: setupSecret}}>
      <SetupForm />
    </Elements>):(<LoadingScreen></LoadingScreen>)
    ) :(null)}
    </>
    </Center>
    </>
  );
};

export default PaymentSetup;