import { Text, Textarea, Button, Flex } from '@chakra-ui/react';
import copy from 'copy-to-clipboard';
import { useRef } from 'react';
import {
  CopyIcon
} from '@chakra-ui/icons';
// const EssayHolder= ({ label, valueOfText }) => {

//   return (
//     <>
//         <Text textAlign={"center"} htmlFor="textarea">{label}</Text>
//         <Textarea readOnly border={"1px"} id="textarea" value={valueOfText}  />
//     </>

//   );
// };

// export default EssayHolder;

const EssayHolder= ({ label, valueOfText }) => {
    const textareaRef = useRef(null);
  
    function handleCopy() {
      copy(textareaRef.current.value);
    }
  
    return (
      <>
      { valueOfText.trim() === ""? (null) : (
        <div style={{ position: "relative", marginTop: "15px" }}>
      <Button position={"absolute"} top={"-5"} right={"0"} onClick={handleCopy}>Copy<CopyIcon></CopyIcon></Button>
      <Text textAlign={["left","center"]} htmlFor="textarea">{label}</Text>
      <Textarea height={["400px"]} ref={textareaRef} readOnly border={"1px"} id="textarea" value={valueOfText}  />
    </div>

      )

      }
      </>
    );
  };
  
  export default EssayHolder;