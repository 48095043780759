import { initializeApp } from "firebase/app";
// import "firebase/auth";
// import "firebase/firestore";

// Firebase configuration variables loaded from environment variables
const clientCredentials = {
    apiKey: "AIzaSyBwIfGS-CQyCpnT5Jnjt3NdQry6OC2Ie0U",
    authDomain: "textmatica.firebaseapp.com",
    projectId: "textmatica",
    storageBucket: "textmatica.appspot.com",
    messagingSenderId: "328176311592",
    appId: "1:328176311592:web:33eb021ea17d452602841d",
    measurementId: "G-ZYTJR4E4MY"
};

// If Firebase isn't already initialized, initialize it using the above credentials.

const app = initializeApp(clientCredentials);


export default app;