import {
    Box,
    Flex,
    Text,
    Stack,
    Collapse,
    Icon,
    Link as ReachLink,
    Avatar,
    useColorModeValue,
    useDisclosure,
    Image,
    MenuButton,
    Menu,
    MenuList,
    MenuItem,
  } from '@chakra-ui/react';
  import {
    ChevronDownIcon,
    ChevronRightIcon,
  } from '@chakra-ui/icons';
import Breadcrumbs from '../Breadcrumbs';
  import {useState} from 'react'
import { useLocation, Link } from 'react-router-dom';
// import logo from "../assets/logo_top.png"
import { ColorModeSwitcher } from '../ColorModeSwitcher';
import logo from '../assets/logo1.png'
import wlogo from '../assets/w_logo1.png'
import logoutlogo from '../assets/logout.png'
import logoutlogodark from '../assets/logout_dark.png'
  export default function WithSubnavigation(props) {
    const { isOpen, onToggle } = useDisclosure();
    const [hoveringLogin, setHoveringLogin] = useState(false)

    const handleMouseOverLogin = () =>{
      console.log("in")
      setHoveringLogin(true)
    }
    const handleMouseOutLogin = () =>{
      console.log("out")
      setHoveringLogin(true)
    }
    const location = useLocation();
    return (
      <Box>
        <Flex
          bg={useColorModeValue('white', 'gray.800')}
          color={useColorModeValue('gray.600', 'white')}
          minH={'60px'}
          py={{ base: 2 }}
          px={{ base: 4 }}

          align={'center'}>
          {/* <a href='https://textmatica.com' target={"blank"}>
          <Image height={"40px"} src={useColorModeValue(logo, wlogo)} marginLeft={"10px"}
          ></Image>
          </a> */}
          <Flex
            flex={{ base: 1, md: 'auto' }}
            ml={{ base: -2 }}
            display={{ base: 'flex', md: 'none' }}>
            

          </Flex>
          <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>

            <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
              <DesktopNav />
            </Flex>
          </Flex>

          {location.pathname === "/" || location.pathname === "/realtor" ? (null):(<Flex flex={[10,8,2]} >
      <Breadcrumbs/>
    </Flex>)}
  <Menu>
  <MenuButton position={["absolute","absolute","relative"]} top={["10px","10px","0"]} right={["10px","10px","0"]}>
  <Flex>    
            {props.currentUser === "" ? (null):(      
              <>
            {/* <Avatar marginRight={"20px"} size="md" name={props.currentUser.displayName} src={props.currentUser.photoURL} referrerPolicy={"no-referrer"}>
            </Avatar> */}
            <Avatar marginRight={"20px"} size="md" name={props.currentUser.displayName} referrerPolicy={"no-referrer"}>
            </Avatar>
            <Flex position={["absolute","absolute","relative"]} visibility={["hidden","hidden","visible"]} direction="column">
            </Flex>
            </>)
            }
    </Flex>
  </MenuButton>
  <MenuList >
  <Link to="/userinfo">
    <MenuItem padding={"20px"} marginBottom={"20px"}>My Account</MenuItem>
    </Link>
    <MenuItem padding={"20px"} marginBottom={"20px"} onClick={props.handleLogout}>Log out</MenuItem>
    <ColorModeSwitcher padding={"20px"} justifySelf="flex-end" width="92%"/>
  </MenuList>
</Menu>

          
          <Stack
            flex={{ base: 1, md: 0 }}
            justify={'flex-end'}
            direction={'row'}
            spacing={6}>
           
          </Stack>

        </Flex>
      </Box>
    );
  }
  
  const DesktopNav = () => {
    const linkColor = useColorModeValue('gray.600', 'gray.200');
    const linkHoverColor = useColorModeValue('gray.800', 'white');
    const popoverContentBgColor = useColorModeValue('white', 'gray.800');
  
    return (
      <Stack direction={'row'} spacing={4}>
        {NAV_ITEMS.map((navItem) => (
          <Box key={navItem.label}>

          </Box>
        ))}
      </Stack>
    );
  };
  
  const DesktopSubNav = ({ label, href, subLabel }) => {
    return (
      <Link
        href={href}
        role={'group'}
        display={'block'}
        p={2}
        rounded={'md'}
        target={'2'}
        _hover={{ bg: useColorModeValue('pink.50', 'gray.900') }}>
        <Stack direction={'row'} align={'center'}>
          <Box>
            <Text
              transition={'all .3s ease'}
              _groupHover={{ color: 'pink.400' }}
              fontWeight={500}>
              {label}
            </Text>
            <Text fontSize={'sm'}>{subLabel}</Text>
          </Box>
          <Flex
            transition={'all .3s ease'}
            transform={'translateX(-10px)'}
            opacity={0}
            _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
            justify={'flex-end'}
            align={'center'}
            flex={1}>
            <Icon color={'pink.400'} w={5} h={5} as={ChevronRightIcon} />
          </Flex>
        </Stack>
      </Link>
    );
  };
  
  const MobileNav = () => {
    return (
      <Stack
        bg={useColorModeValue('white', 'gray.800')}
        p={4}
        display={{ md: 'none' }}>
        {NAV_ITEMS.map((navItem) => (
          <MobileNavItem key={navItem.label} {...navItem} />
        ))}
      </Stack>
    );
  };
  
  const MobileNavItem = ({ label, children, href }) => {
    const { isOpen, onToggle } = useDisclosure();
  
    return (
      <Stack spacing={4} onClick={children && onToggle}>
        <Flex
          py={2}
          as={Link}
          href={href ?? '#'}
          justify={'space-between'}
          align={'center'}
          _hover={{
            textDecoration: 'none',
          }}>
          <Text
            fontWeight={600}
            color={useColorModeValue('gray.600', 'gray.200')}>
            {label}
          </Text>
          {children && (
            <Icon
              as={ChevronDownIcon}
              transition={'all .25s ease-in-out'}
              transform={isOpen ? 'rotate(180deg)' : ''}
              w={6}
              h={6}
            />
          )}
        </Flex>
  
        <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
          <Stack
            mt={2}
            pl={4}
            borderLeft={1}
            borderStyle={'solid'}
            borderColor={useColorModeValue('gray.200', 'gray.700')}
            align={'start'}>
            {children &&
              children.map((child) => (
                <Link key={child.label} target={'3'} py={2} href={child.href}>
                  {child.label}
                </Link>
              ))}
          </Stack>
        </Collapse>
      </Stack>
    );
  };
  

  const NAV_ITEMS = [
    {
      label: 'TextMatica',
      href: 'https://textmatica.com',
    },
   
  ];