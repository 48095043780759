import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import {Link} from "react-router-dom"
import {    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
  } from '@chakra-ui/react';

  import {
    ArrowForwardIcon,
  } from '@chakra-ui/icons';

  const routes = [
    { path: '/tools/topicwriter', breadcrumb: 'Topic Writer' },
    { path: '/userinfo', breadcrumb: 'My Account' },
    { path: '/billingsetup', breadcrumb: 'Billing Setup' }
  ];

const Breadcrumbs = ({ match, breadcrumbs }) => (
    <Breadcrumb separator={<ArrowForwardIcon color='gray.500' marginBottom={"3px"} />}>
    {breadcrumbs.map(({ match, breadcrumb}) => 
    <BreadcrumbItem key={match.url} >
    <BreadcrumbLink fontSize={{base:"10px", md:"12px"}} as={Link} to={match.url}>
        {breadcrumb}
    </BreadcrumbLink>
    </BreadcrumbItem>
    
    ) }

</Breadcrumb>
)

export default withBreadcrumbs(routes)(Breadcrumbs);

// const Breadcrumbs = ({ breadcrumbs }) => (
//     <>
// <Breadcrumb spacing='8px' separator={<ChevronRightIcon color='gray.500' />}>
// {breadcrumbs.map(({
//         match,
//         i,
//        breadcrumb
//      }) => (
//         <>
//   <BreadcrumbItem>
//     <BreadcrumbLink as={Link} href='#'>Home</BreadcrumbLink>
//   </BreadcrumbItem>
//   </>))}
// </Breadcrumb>

// </>
// )



// export default withBreadcrumbs()(Breadcrumbs);

// const Breadcrumbs = ({ breadcrumbs }) => (
//     <div>
//       {breadcrumbs.map(({
//         match,
//         i,
//         breadcrumb
//       }) => (
//         <span key={match.url}>
//         {console.log(     match,
//         i,
//         breadcrumb)}
//           <Link to={match.url}>{breadcrumb}</Link>
//         </span>
//       ))}
//     </div>
//   );

//   export default withBreadcrumbs()(Breadcrumbs);