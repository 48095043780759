import { useHistory } from "react-router-dom";
import { Button } from "@chakra-ui/react";
function BackButton() {
  const history = useHistory();

  function handleClick() {
    history.goBack();
  }

  return (
    <Button onClick={handleClick}>
      Go back
    </Button>
  );
}

export default BackButton;