import { mode } from '@chakra-ui/theme-tools';
import { extendTheme } from '@chakra-ui/react';

const styles = {
  global: props => ({
    body: {
      color: mode('gray.800', 'whiteAlpha.900')(props),
      bg: mode('gray.100', '#141214')(props),
    },
  }),
};

const components = {
  Drawer: {
    // setup light/dark mode component defaults
    baseStyle: props => ({
      dialog: {
        bg: mode('white', '#141214')(props),
      },
    }),
  },
};

const fontFamilies = {
  sans: "Open Sans, sans-serif",
  mono: "Roboto Mono, monospace",
}

const theme = extendTheme({
  // components,
  // styles,
  fonts: {
    body: fontFamilies.mono,
    heading: fontFamilies.mono,
  }
});

export default theme;